<template>
    <div class="container mt-3">
        <h2 v-if="phase.phaseid == 0">Neue Heilungsphase</h2>
        <h2 v-else>Heilungsphase bearbeiten</h2>
        <form @submit.prevent="savePhase(phase.phaseid)">
            <div class="form-group">
                <label for="title">Titel</label>
                <input type="text" class="form-control" id="title" v-model="phase.title">
            </div>
            <div class="form-group">
                <label class="col-sm-3" for="start">Von Woche</label>
                <input class="col-sm-3 form-control" type="number" id="start" v-model="phase.startweek">
                <label class="col-sm-3" for="end">Bis</label>
                <input class="col-sm-3 form-control" type="number" id="end" v-model="phase.endweek">
            </div>
            <div class="dropdown">
                <label for="healingpath">Heilungspfad</label>
                <multiselect v-model="phase.healingpathid" :options="healingpathOptions" label="dropdownText"
                    valueProp="healingpathid" trackBy="healingpathid" mode="single">
                </multiselect>
            </div>
            <div class="form-group">
                <label for="content">Beschreibung</label>
                <vue-editor v-model="phase.htmlcontent"></vue-editor>
            </div>
            <button type="submit" class="btn btn-primary">Speichern</button>
        </form>
    </div>
</template>

<script>
import { VueEditor } from "vue3-editor";
import axios from "axios";
import Multiselect from '@vueform/multiselect';

export default {
    components: {
        VueEditor,
        Multiselect,
    },
    data() {
        return {
            phase: {
                phaseid: this.$route.params.id,
                title: '',
                htmlcontent: '',
                startweek: 0,
                endweek: 0,
                healingpathid: 0,
            },
            healingpathOptions: [],
        };
    },
    mounted() {
        this.loadHealingpathOptions();
        if (this.phase.phaseid !== '0') {
            this.loadPhase(this.phase.phaseid);
        }
    },
    methods: {
        async loadHealingpathOptions() {
            const { data } = await axios.get("https://svrz5379.spital.lokal/api/healingpath/options");
            data.forEach((path) => {
                path.dropdownText = path.title + " " + path.procedure;
                this.healingpathOptions.push(path)
            });
        },
        async loadPhase(id) {
            const { data } = await axios.get("https://svrz5379.spital.lokal/api/phase/" + id);
            this.phase = data;
        },
        async savePhase(id) {
            try {
                var data = {};
                if (id == 0) {
                    data = await axios.post("https://svrz5379.spital.lokal/api/phase", this.phase);
                } else {
                    data = await axios.put("https://svrz5379.spital.lokal/api/phase/" + id, this.phase);
                }
                this.phase = data;
                console.log("Phase saved", this.phase);
                this.$router.push({ name: 'phases' });
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
