<template>
    <div>
        <h2>Dashboard</h2>
        <div class="col-lg-9">
            <h5>Aktive User im letzten Jahr</h5>
            <Bar v-if="loaded" id="activeUsers" :options="chartOptions" :data="chartData" ref="activeUsers" />
        </div>
    </div>

</template>

<script>
import { Bar } from 'vue-chartjs';
import axios from 'axios';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'BarChart',
    components: { Bar },
    data() {
        return {
            loaded: false,
            months: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
            chartData: {
                labels: [],
                datasets: [{
                    label: 'Aktive User',
                    data: [],
                    backgroundColor: 'blue',
                }]
            },
            chartOptions: {
                responsive: true,
                scales: {
                    y: {
                        display: true,
                        ticks: {
                            beginAtZero: true
                        }
                    }
                }
            }
        }
    },
    mounted() {
        this.loadStats();
    },
    methods: {
        async loadStats() {
            await axios.get("https://svrz5379.spital.lokal/api/user/stats").then(response => {
                var max = Math.max(...response.data);
                var newMax = Math.ceil(max / 10) * 10;
                const newData = [];
                const newLabels = [];
                var date = new Date();
                var month = date.getMonth();
                for (let i = 0; i < 12; i++) {
                    newData[11 - i] = response.data[(month - i + 12) % 12];
                    newLabels[11 - i] = this.months[(month - i + 12) % 12];
                }
                this.chartData = {
                    ...this.chartData,
                    labels: newLabels,
                    datasets: [{
                        label: 'Aktive User',
                        data: newData,
                        backgroundColor: 'blue',
                    }]
                };
                this.chartOptions = {
                    ...this.chartOptions,
                    scales: {
                        y: {
                            display: true,
                            max: newMax,
                            ticks: {
                                beginAtZero: true,
                                stepSize: newMax / 10
                            }
                        }
                    }
                };
                this.loaded = true;
            });

        },
    }
}
</script>
