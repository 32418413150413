<template>
    <div>
        <h2>Sonstige Inhalte</h2>
        <table class="table">
            <thead>
                <tr>
                    <th>Titel</th>
                    <th>Aktionen</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="c in contents" v-bind:key="c.contentid">
                    <td>{{ c.title }}</td>
                    <td>
                        <button class="btn btn-info" @click="editContent(c.title)">Bearbeiten</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            contents: [],
        };
    },
    mounted() {
        this.loadContents();
    },
    methods: {
        async loadContents() {
            const { data } = await axios.get("https://svrz5379.spital.lokal/api/content");
            this.contents = data;
        },
        editContent(title) {
            this.$router.push({ path: `/contents/edit/` + title });
        },
    },
};
</script>
