<template>
    <div class="col-md-2 d-none d-md-block bg-light sidebar" style="min-height: 100vh">
        <div class="sidebar-sticky">
            <ul class="nav flex-column">
                <li class="nav-item">
                    <router-link to="/" class="nav-link">Übersicht</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/exercises" class="nav-link">Übungen</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/phases" class="nav-link">Phasen</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/faqs" class="nav-link">Häufige Fragen</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/operations" class="nav-link">Inhalte Operation</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/contents" class="nav-link">Sonstige Inhalte</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/users" class="nav-link">User Passwort vergessen</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {};
</script>
