<template>
    <div class="container mt-3">
        <h2 v-if="content.contentid == 0">Neuer Inhalt</h2>
        <h2 v-else>Inhalt bearbeiten</h2>
        <form @submit.prevent="saveContent(content.contentid)">
            <div class="form-group">
                <label for="title">Titel</label>
                <input type="text" class="form-control" id="title" v-model="content.title" disabled>
            </div>
            <div class="form-group">
                <label for="content">Inhalt</label>
                <vue-editor v-model="content.htmlcontent"></vue-editor>
            </div>
            <button type="submit" class="btn btn-primary">Speichern</button>
        </form>
    </div>
</template>

<script>
import { VueEditor } from "vue3-editor";
import axios from "axios";

export default {
    components: {
        VueEditor,
    },
    data() {
        return {
            content: {
                contentid: this.$route.params.id,
                title: '',
                htmlcontent: ''
            },
        };
    },
    mounted() {
        if (this.content.contentid !== '0') {
            this.loadContent(this.content.contentid);
        }
    },
    methods: {
        async loadContent(id) {
            const { data } = await axios.get("https://svrz5379.spital.lokal/api/content/" + id);
            this.content = data;
        },
        async saveContent(id) {
            try {
                var data = {};
                if (id == 0) {
                    data = await axios.post("https://svrz5379.spital.lokal/api/content", this.content);
                } else {
                    data = await axios.put("https://svrz5379.spital.lokal/api/content/" + id, this.content);
                }
                this.content = data;
                console.log("Content saved", this.content);
                this.$router.push({ name: 'contents' });
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
