<template>
    <div class="container mt-3">
        <h2 v-if="faq.faqid == 0">Neue Frage</h2>
        <h2 v-else>Frage bearbeiten</h2>
        <form @submit.prevent="saveFaq(faq.faqid)">
            <div class="dropdown">
                <label for="topic">Thema</label>
                <multiselect v-model="faq.faqtopicid" :options="topicOptions" label="title" valueProp="faqtopicid"
                    trackBy="faqtopicid" mode="single">
                </multiselect>
            </div>
            <div v-if="faq.faqtopicid == -1" class="form-group">
                <label for="question">Neues Thema</label>
                <input type="text" class="form-control" id="question" v-model="newTopic.title">
            </div>
            <div v-if="faq.faqtopicid == -1" class="dropdown">
                <label for="procedure">Eingriff</label>
                <multiselect v-model="newTopic.procedureid" :options="procedureOptions" label="title"
                    valueProp="procedureid" trackBy="procedureid" mode="single">
                </multiselect>
            </div>
            <div class="form-group">
                <label for="question">Frage</label>
                <input type="text" class="form-control" id="question" v-model="faq.question">
            </div>
            <div class="form-group">
                <label for="content">Antwort</label>
                <vue-editor v-model="faq.answer"></vue-editor>
            </div>
            <button type="submit" class="btn btn-primary">Speichern</button>
        </form>
    </div>
</template>

<script>
import { VueEditor } from "vue3-editor";
import axios from "axios";
import Multiselect from '@vueform/multiselect';

export default {
    components: {
        VueEditor,
        Multiselect,
    },
    data() {
        return {
            faq: {
                faqid: this.$route.params.id,
                faqtopicid: 0,
                question: '',
                answer: ''
            },
            topicOptions: [],
            procedureOptions: [],
            newTopic: {
                title: "",
                procedureid: 0,
            },
        };
    },
    mounted() {
        this.loadTopicOptions();
        this.loadProcedureOptions();
        if (this.faq.faqid !== '0') {
            this.loadFaq(this.faq.faqid);
        }
    },
    methods: {
        async loadTopicOptions() {
            const { data } = await axios.get("https://svrz5379.spital.lokal/api/faqtopic");
            this.topicOptions = data;
            var newTopic = {
                faqtopicid: -1,
                title: "Neues Thema erstellen"
            }
            this.topicOptions.push(newTopic)
        },
        async loadProcedureOptions() {
            const { data } = await axios.get("https://svrz5379.spital.lokal/api/procedure");
            this.procedureOptions = data;
        },
        async loadFaq(id) {
            const { data } = await axios.get("https://svrz5379.spital.lokal/api/faq/" + id);
            this.faq = data;
        },
        async saveFaq(id) {
            try {
                var data = {};
                if (this.faq.faqtopicid == -1) {
                    await axios.post("https://svrz5379.spital.lokal/api/faqtopic", this.newTopic).then(resp => {
                        this.faq.faqtopicid = resp.data.faqtopicid;
                        console.log("Topic saved", this.faq);
                    });
                }
                if (id == 0) {
                    data = await axios.post("https://svrz5379.spital.lokal/api/faq", this.faq);
                } else {
                    data = await axios.put("https://svrz5379.spital.lokal/api/faq/" + id, this.faq);
                }
                this.faq = data;
                console.log("Faq saved", this.faq);
                this.$router.push({ name: 'faqs' });
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
