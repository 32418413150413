<template>
    <div>
        <h2>Häufige Fragen</h2>
        <button class="btn btn-primary" @click="createNew()">Neue Frage</button>
        <div v-for="p in procedures" v-bind:key="p.procedureid">
            <h5>{{ p.title }}</h5>
            <h6 v-if="p.faqtopics.length == 0">Keine Themen erfasst</h6>
            <div v-for="t in p.faqtopics" v-bind:key="t.faqtopicid">
                <h6>{{ t.title }}</h6>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Frage</th>
                            <th>Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="f in t.faqs" v-bind:key="f.faqid">
                            <td>{{ f.question }}</td>
                            <td>
                                <button class="btn btn-info" @click="editFaq(f.faqid)">Bearbeiten</button>
                                <button class="btn btn-danger" @click="deleteFaq(f.faqid)">Löschen</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr>
            </div>
            <hr>
            <br>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            procedures: [],
        };
    },
    mounted() {
        this.loadFaqs();
    },
    methods: {
        async loadFaqs() {
            const { data } = await axios.get("https://svrz5379.spital.lokal/api/faq");
            this.procedures = data;
        },
        createNew() {
            this.$router.push({ path: `/faqs/edit/0` });
        },
        editFaq(id) {
            this.$router.push({ path: `/faqs/edit/` + id });
        },
        deleteFaq(id) {
            if (confirm("Frage wirklich löschen? Wird die letzte Frage eines Themas gelöscht, wird das Thema ebenfalls gelöscht.")) {
                axios.delete('https://svrz5379.spital.lokal/api/faq/' + id)
                    .then(resp => {
                        console.log("deleted", resp);
                        this.loadFaqs();
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    },
};
</script>
