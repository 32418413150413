import { createRouter, createWebHistory } from 'vue-router';
import DashboardView from '../views/DashboardView.vue';
import ExercisesView from '../views/ExercisesView.vue';
import PhasesView from '../views/PhasesView.vue';
import FAQView from '../views/FAQView.vue';
import ContentsView from '../views/ContentsView.vue';
import OperationsView from '@/views/OperationsView.vue';
import EditExercise from '../views/EditExercise.vue';
import LoginPage from '../components/LoginPage.vue';
import store from '@/store';
import EditContent from '@/views/EditContent.vue';
import EditOperation from '@/views/EditOperation.vue';
import EditFAQ from '@/views/EditFAQ.vue';
import EditPhase from '@/views/EditPhase.vue';
import UsersView from '@/views/UsersView.vue';


const routes = [
  { path: '/', name: 'home', component: DashboardView, meta: { requiresAuth: true } },
  { path: '/exercises', name: 'exercises', component: ExercisesView, meta: { requiresAuth: true } },
  { path: '/phases', name: 'phases', component: PhasesView, meta: { requiresAuth: true } },
  { path: '/faqs', name: 'faqs', component: FAQView, meta: { requiresAuth: true } },
  { path: '/contents', name: 'contents', component: ContentsView, meta: { requiresAuth: true } },
  { path: '/operations', name: 'operations', component: OperationsView, meta: { requiresAuth: true } },
  { path: '/exercises/edit/:id', name: 'editExercise', component: EditExercise, meta: { requiresAuth: true } },
  { path: '/contents/edit/:id', name: 'editContent', component: EditContent, meta: { requiresAuth: true } },
  { path: '/operations/edit/:id', name: 'editOperation', component: EditOperation, meta: { requiresAuth: true } },
  { path: '/faqs/edit/:id', name: 'editFaq', component: EditFAQ, meta: { requiresAuth: true } },
  { path: '/phases/edit/:id', name: 'editPhase', component: EditPhase, meta: { requiresAuth: true } },
  { path: '/users/', name: 'users', component: UsersView, meta: { requiresAuth: true } },
  { path: '/login', name: 'login', component: LoginPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = store.state.username;
  if (requiresAuth && !isAuthenticated) {
    next({ name: 'login' });
  } else if (to.name == 'login' && isAuthenticated) {
    next({ name: 'home' });
  } else {
    next();
  }
});

export default router;
