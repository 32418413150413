<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <a class="navbar-brand" href="#">soH Recovery Admin</a>
      <button v-if="$store.state.username" class="btn btn-outline-success my-2 my-sm-0 ml-auto" type="submit"
        @click="logout">Logout</button>
    </nav>
    <div class="container-fluid">
      <div class="row">
        <sidebar v-if="$store.state.username"></sidebar>
        <div v-if="$store.state.username" class="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
          <router-view></router-view>
        </div>
        <div v-else class="col ml-sm-auto col pt-3 px-4">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from './components/SidebarNav.vue';

export default {
  components: {
    Sidebar,
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
        .then(() => {
          this.$router.push({ name: 'login' });
        })
        .catch((error) => {
          console.error("Logout failed:", error);
        });
    }
  }
};
</script>
<style>
.btn {
  margin-right: 10px;
}

.btn-primary {
  margin: 10px 0;
}

.nav-item:hover {
  background: lightgray;
}
</style>
