<template>
    <div>
        <h2>User</h2>
        <p>Falls ein User das Passwort vergessen hat, können Sie hier den User während einer Konsultation ein neues
            Passwort setzen lassen.</p>
        <table class="table">
            <thead>
                <tr>
                    <th>Username</th>
                    <th>Neues Passwort</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="u in users" v-bind:key="u.userid">
                    <td>{{ u.username }}</td>
                    <td><input type="password" class="form-control" v-model="u.password" /></td>
                    <td>
                        <button class="btn btn-danger" @click="setPassword(u)">Neues Passwort speichern</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            users: [],
        };
    },
    mounted() {
        this.loadPhases();
    },
    methods: {
        async loadPhases() {
            const { data } = await axios.get("https://svrz5379.spital.lokal/api/user");
            this.users = data;
        },
        async setPassword(user) {
            if (confirm("Passwort wirklich ändern?")) {
                try {
                    await axios.put("https://svrz5379.spital.lokal/api/user/" + user.userid, user).then(response => {
                        if (response.status == 204) {
                            alert("Password geändert");
                            user.password = "";
                        } else {
                            alert("Passwort konnte nicht geändert werden");
                        }
                    });
                } catch (error) {
                    alert("Passwort konnte nicht geändert werden");
                    console.log(error);
                }

            }
        },
    },
};
</script>
