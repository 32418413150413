<template>
    <div>
        <h2>Inhalte Operation</h2>
        <button class="btn btn-primary" @click="createNew()">Neuer Inhalt</button>
        <div v-for="p in procedures" v-bind:key="p.procedureid">
            <h5>{{ p.title }}</h5>
            <table class="table">
                <thead>
                    <tr>
                        <th>Titel</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="o in p.operations" v-bind:key="o.operationid">
                        <td>{{ o.title }}</td>
                        <td>
                            <button class="btn btn-info" @click="editOperation(o.operationid)">Bearbeiten</button>
                            <button class="btn btn-danger" @click="deleteOperation(o.operationid)">Löschen</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <br>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            procedures: [],
        };
    },
    mounted() {
        this.loadOperations();
    },
    methods: {
        async loadOperations() {
            const { data } = await axios.get("https://svrz5379.spital.lokal/api/operation");
            this.procedures = data;
        },
        createNew() {
            this.$router.push({ path: `/operations/edit/0` });
        },
        editOperation(id) {
            this.$router.push({ path: `/operations/edit/` + id });
        },
        deleteOperation(id) {
            if (confirm("Inhalt wirklich löschen?")) {
                axios.delete('https://svrz5379.spital.lokal/api/operation/' + id)
                    .then(resp => {
                        console.log("deleted", resp);
                        this.loadOperations();
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    },
};
</script>
