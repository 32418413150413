<template>
    <div class="container mt-5">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">
                        <h2 class="mb-0">Login</h2>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="login">
                            <div class="mb-3">
                                <label for="username" class="form-label">Username:</label>
                                <input type="text" class="form-control" :class="{ 'is-invalid': wrongCredentials }"
                                    id="username" v-model="credentials.username" required>
                            </div>
                            <div class="mb-3">
                                <label for="password" class="form-label">Password:</label>
                                <input type="password" class="form-control" :class="{ 'is-invalid': wrongCredentials }"
                                    id="password" v-model="credentials.password" required>
                            </div>
                            <div class="invalid-feedback" v-if="wrongCredentials">
                                Benutzername oder Passwort falsch.
                            </div>
                            <button type="submit" class="btn btn-primary">Login</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            credentials: {
                username: '',
                password: ''
            },
            wrongCredentials: false
        };
    },
    methods: {
        login() {
            this.$store.dispatch('login', this.credentials)
                .then(() => {
                    if (this.$store.state.username) {
                        this.$router.push({ name: 'home' });
                    }
                })
                .catch((error) => {
                    console.error("Login failed:", error);
                    this.wrongCredentials = true;
                });
        }
    }
};
</script>
