<template>
    <div class="container mt-3">
        <h2 v-if="operation.operationid == 0">Neuer Inhalt</h2>
        <h2 v-else>Inhalt bearbeiten</h2>
        <form @submit.prevent="saveOperation(operation.operationid)">
            <div class="dropdown">
                <label for="procedure">Eingriff</label>
                <multiselect v-model="operation.procedureid" :options="procedureOptions" label="title"
                    valueProp="procedureid" trackBy="procedureid" mode="single">
                </multiselect>
            </div>
            <div class="form-group">
                <label for="title">Titel</label>
                <input type="text" class="form-control" id="title" v-model="operation.title">
            </div>
            <div class="form-group">
                <label for="operation">Inhalt</label>
                <vue-editor v-model="operation.htmlcontent"></vue-editor>
            </div>
            <button type="submit" class="btn btn-primary">Speichern</button>
        </form>
    </div>
</template>

<script>
import { VueEditor } from "vue3-editor";
import axios from "axios";
import Multiselect from "@vueform/multiselect";

export default {
    components: {
        VueEditor,
        Multiselect
    },
    data() {
        return {
            operation: {
                operationid: this.$route.params.id,
                title: '',
                htmlcontent: '',
                procedureid: '',
            },
            procedureOptions: []
        };
    },
    mounted() {
        this.loadProcedureOptions();
        if (this.operation.operationid !== '0') {
            this.loadOperation(this.operation.operationid);
        }
    },
    methods: {
        async loadProcedureOptions() {
            const { data } = await axios.get("https://svrz5379.spital.lokal/api/procedure");
            this.procedureOptions = data;
        },
        async loadOperation(id) {
            const { data } = await axios.get("https://svrz5379.spital.lokal/api/operation/" + id);
            this.operation = data;
        },
        async saveOperation(id) {
            try {
                var data = {};
                if (id == 0) {
                    data = await axios.post("https://svrz5379.spital.lokal/api/operation", this.operation);
                } else {
                    data = await axios.put("https://svrz5379.spital.lokal/api/operation/" + id, this.operation);
                }
                this.operation = data;
                console.log("Operation saved", this.operation);
                this.$router.push({ name: 'operations' });
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
