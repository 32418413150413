<template>
    <div>
        <h2>Heilungsphasen</h2>
        <button class="btn btn-primary" @click="createNew()">Neue Phase</button>
        <div v-for="p in procedures" v-bind:key="p.procedureid">
            <h5>{{ p.title }}</h5>
            <div v-for="h in p.healingpaths" v-bind:key="h.healingpathid">
                <h6>{{ h.title }}</h6>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Titel</th>
                            <th>Wochen</th>
                            <th>Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="p in h.phases" v-bind:key="p.phaseid">
                            <td>{{ p.title }}</td>
                            <td>{{ p.startweek + " bis " + p.endweek }}</td>
                            <td>
                                <button class="btn btn-info" @click="editPhase(p.phaseid)">Bearbeiten</button>
                                <button class="btn btn-danger" @click="deletePhase(p.phaseid)">Löschen</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr>
            </div>
            <hr>
            <br>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            procedures: [],
        };
    },
    mounted() {
        this.loadPhases();
    },
    methods: {
        async loadPhases() {
            const { data } = await axios.get("https://svrz5379.spital.lokal/api/phase");
            this.procedures = data;
        },
        createNew() {
            this.$router.push({ path: `/phases/edit/0` });
        },
        editPhase(id) {
            this.$router.push({ path: `/phases/edit/` + id });
        },
        deletePhase(id) {
            if (confirm("Phase wirklich löschen?")) {
                axios.delete('https://svrz5379.spital.lokal/api/phase/' + id)
                    .then(resp => {
                        console.log("deleted", resp);
                        this.loadPhases();
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    },
};
</script>
