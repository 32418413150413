<template>
    <div class="container mt-3">
        <h2 v-if="exercise.exerciseid == 0">Neue Übung</h2>
        <h2 v-else>Übung bearbeiten</h2>
        <form @submit.prevent="saveExercise(exercise.exerciseid)">
            <div class="form-group">
                <label for="title">Titel</label>
                <input type="text" class="form-control" id="title" v-model="exercise.title">
            </div>
            <div class="form-group">
                <label for="content">Beschreibung</label>
                <vue-editor v-model="exercise.htmlcontent"></vue-editor>
            </div>
            <div>
                <label for="phases">Phasen</label>
                <multiselect v-model="exercise.phaseids" :options="phases" label="dropdownText" valueProp="phaseid"
                    trackBy="phaseid" mode="tags" :breakTags="true" :close-on-select="false">
                </multiselect>
            </div>
            <button type="submit" class="btn btn-primary">Speichern</button>
        </form>
    </div>
</template>

<script>
import { VueEditor } from "vue3-editor";
import axios from "axios";
import Multiselect from '@vueform/multiselect';

export default {
    components: {
        VueEditor,
        Multiselect,
    },
    data() {
        return {
            exercise: {
                exerciseid: this.$route.params.id,
                title: '',
                htmlcontent: '',
                phaseids: [],
                file: null,
            },
            phases: [],
        };
    },
    mounted() {
        this.loadPhases();
        if (this.exercise.exerciseid !== '0') {
            this.loadExercise(this.exercise.exerciseid);
        }
    },
    methods: {
        async loadPhases() {
            const { data } = await axios.get("https://svrz5379.spital.lokal/api/phase/options");
            data.forEach((phase) => {
                phase.dropdownText = phase.title + " " + phase.healingpath + " " + phase.procedure;
                this.phases.push(phase)
            });
        },
        async loadExercise(id) {
            const { data } = await axios.get("https://svrz5379.spital.lokal/api/exercise/" + id);
            this.exercise = data;
        },
        async saveExercise(id) {
            try {
                var data = {};
                if (id == 0) {
                    data = await axios.post("https://svrz5379.spital.lokal/api/exercise", this.exercise);
                } else {
                    data = await axios.put("https://svrz5379.spital.lokal/api/exercise/" + id, this.exercise);
                }
                this.exercise = data;
                this.$router.push({ name: 'exercises' });
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
