<template>
    <div>
        <h2>Übungen</h2>
        <button class="btn btn-primary" @click="createNew()">Neue Übung</button>
        <table class="table">
            <thead>
                <tr>
                    <th>Titel</th>
                    <th>Aktionen</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="e in exercises" v-bind:key="e.exerciseid">
                    <td>{{ e.title }}</td>
                    <td>
                        <button class="btn btn-info" @click="editExercise(e.exerciseid)">Bearbeiten</button>
                        <button class="btn btn-danger" @click="deleteExercise(e.exerciseid)">Löschen</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            exercises: [],
        };
    },
    mounted() {
        this.loadExercises();
    },
    methods: {
        async loadExercises() {
            const { data } = await axios.get("https://svrz5379.spital.lokal/api/exercise");
            this.exercises = data;
        },
        createNew() {
            this.$router.push({ path: `/exercises/edit/0` });
        },
        editExercise(id) {
            this.$router.push({ path: `/exercises/edit/` + id });
        },
        deleteExercise(id) {
            if (confirm("Übung wirklich löschen?")) {
                axios.delete('https://svrz5379.spital.lokal/api/exercise/' + id)
                    .then(resp => {
                        console.log("deleted", resp);
                        this.loadExercises();
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    },
};
</script>
