import Vuex from 'vuex';
import axios from 'axios';

const store = new Vuex.Store({
    state: {
        username: null,
        password: null,
    },
    mutations: {
        setUser(state, { username, password }) {
            state.username = username;
            state.password = password;
            localStorage.setItem('username', username);
            localStorage.setItem('password', password);
        },
        logout(state) {
            state.username = undefined;
            state.password = undefined;
            localStorage.removeItem('username');
            localStorage.removeItem('password');
        }
    },
    actions: {
        login({ commit }, { username, password }) {
            return new Promise((resolve, reject) => {
                const authHeader = `Basic ${window.btoa(username + ':' + password)}`;
                axios.get('https://svrz5379.spital.lokal/api/User/AuthenticateAdmin', { headers: { Authorization: authHeader } })
                    .then(response => {
                        if (response.status == 204) {
                            commit('setUser', { username, password });
                            axios.defaults.headers.common['Authorization'] = `Basic ${window.btoa(username + ':' + password)}`;
                            resolve(response);
                        } else {
                            console.error("Authentication failed:", response.data);
                            commit('setUser', { username: null, password: null });
                            reject(response);
                        }
                    })
                    .catch(error => {
                        console.error("Authentication failed:", error);
                        commit('setUser', { username: null, password: null });
                        reject(error);
                    });
            });
        },
        logout({ commit }) {
            delete axios.defaults.headers.common['Authorization'];
            commit('logout');
        },
        checkLoginState() {
            const username = localStorage.getItem('username');
            const password = localStorage.getItem('password');
            if (username) {
                axios.defaults.headers.common['Authorization'] = `Basic ${window.btoa(username + ':' + password)}`;
                this.state.username = username;
                this.state.password = password;
            }
        }
    },
});

store.dispatch('checkLoginState');

export default store;
